@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css';
@import '../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ag-header-cell {
  font-size: 14px;
}


.ag-header-cell:hover .ag-header-cell-filter-button {
  opacity: 1;
}

.ag-header-cell .ag-header-cell-filter-button {
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  
}

